import React, { useState } from 'react'
import { Box, Text, Grid, Flex } from 'theme-ui'
import Collapsible from 'react-collapsible'

import Minus from '../../icons/Minus'
import Plus from '../../icons/Plus'

export default function Questions() {
    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                py: [8, 8, 9],
                px: [5, 5, 6],
            }}
        >
            <Box sx={{ maxWidth: 1230 }}>
                <Grid gap={5} columns={['1fr', '1fr', '500px auto']}>
                    <Text
                        as="h2"
                        sx={{
                            fontFamily: 'heading',
                            fontSize: [7, 7, 9],
                            lineHeight: 1.1,
                            mb: [4, 4, 0],
                        }}
                    >
                        Your questions,{' '}
                        <Text as="span" sx={{ fontStyle: 'italic' }}>
                            answered
                        </Text>
                        .
                    </Text>
                    <Box>
                        <Item title="What’s an NFT?" id="what-is-nft">
                            Much like an autographed book or a rare coin, NFTs
                            (non-fungible tokens) are unique digital items that
                            cannot be easily exchanged for something of similar
                            value, simply because their distinctive properties
                            make them one-of-a-kind. Since no two NFTs are
                            exactly alike, their value is based on the rarity of
                            the item and the community driving it.
                        </Item>
                        <Item
                            title="What makes TinyFaces special?"
                            id="what-is-special"
                        >
                            Our community is everything to us, and that’s why we
                            have developed a unique technology that analyses the
                            authenticity of our user base and with that, filters
                            out bots and other spam accounts. By joining
                            TinyFaces, you will access a genuine community of
                            designers, entrepreneurs, artists, and crypto
                            enthusiasts.
                        </Item>

                        <Item
                            title="Who’s the team behind TinyFaces NFT?"
                            id="team-behind"
                        >
                            TinyFaces NFT is a project run by{' '}
                            <b>Maxime De Greve</b> a developer and designer
                            based in London (United Kingdom).
                        </Item>

                        <Item title="When does minting begin?" id="when-mint">
                            Minting is finished and from now the only way to get
                            a TinyFaces NFT is through OpenSea, Rarible,
                            Foundation or any other marketplace.
                        </Item>

                        <Item title="Who can mint one?" id="who-can-mint">
                            Anyone can mint one in any secondary NFT marketplace
                            like OpenSea, Rarible, Foundation...
                        </Item>
                    </Box>
                </Grid>
            </Box>
        </Flex>
    )
}

function Item({ title, children, id }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Collapsible
            triggerElementProps={{ id }}
            contentElementId={id}
            onOpening={() => setIsOpen(true)}
            onClosing={() => setIsOpen(false)}
            trigger={
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTopStyle: 'solid',
                        borderTopColor: 'border',
                        borderTopWidth: 1,
                        cursor: 'pointer',
                        py: [4, 4, 6],
                    }}
                >
                    <Text as="h2" sx={{ fontSize: [4, 4, 5], fontWeight: 400 }}>
                        {title}
                    </Text>
                    {isOpen ? <Minus /> : <Plus />}
                </Flex>
            }
        >
            <Text as="p" sx={{ fontSize: [3, 3, 4], fontWeight: 300, pb: 7 }}>
                {children}
            </Text>
        </Collapsible>
    )
}
