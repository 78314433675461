import { Flex, Box, Text, Grid } from 'theme-ui'
import Image from 'next/image'
import Marquee from 'react-fast-marquee'

export default function Hero() {
    return (
        <Box>
            <Marquee speed={15} gradient={false} style={{ zIndex: 0 }}>
                {[...Array(6)].map((el, i) => (
                    <CharactersTopGrid key={i} />
                ))}
            </Marquee>
            <Marquee
                speed={15}
                direction="right"
                gradient={false}
                style={{ zIndex: 0 }}
            >
                {[...Array(6)].map((el, i) => (
                    <CharactersBottomGrid key={i} />
                ))}
            </Marquee>
            <Marquee gradient={false} style={{ zIndex: 0 }}>
                {[...Array(12)].map((el, i) => (
                    <Ticker key={i} />
                ))}
            </Marquee>
        </Box>
    )
}

function CharactersTopGrid() {
    return (
        <Grid gap={3} columns={8} paddingLeft={3} paddingBottom={3}>
            <Character src="/pages/home/characters/character1.jpg" />
            <Character src="/pages/home/characters/character2.jpg" />
            <Character src="/pages/home/characters/character3.jpg" />
            <Character src="/pages/home/characters/character4.jpg" />
            <Character src="/pages/home/characters/character5.jpg" />
            <Character src="/pages/home/characters/character6.jpg" />
            <Character src="/pages/home/characters/character7.jpg" />
            <Character src="/pages/home/characters/character8.jpg" />
        </Grid>
    )
}

function CharactersBottomGrid() {
    return (
        <Grid gap={3} columns={8} paddingLeft={3} paddingBottom={3}>
            <Character src="/pages/home/characters/character9.jpg" />
            <Character src="/pages/home/characters/character10.jpg" />
            <Character src="/pages/home/characters/character11.jpg" />
            <Character src="/pages/home/characters/character12.jpg" />
            <Character src="/pages/home/characters/character13.jpg" />
            <Character src="/pages/home/characters/character14.jpg" />
            <Character src="/pages/home/characters/character15.jpg" />
            <Character src="/pages/home/characters/character16.jpg" />
        </Grid>
    )
}

function Character({ src }) {
    return (
        <Box
            sx={{
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                width: 288,
                height: 368,
            }}
        >
            <Image
                alt="TinyFaces NFT example artwork"
                src={src}
                fill
                sizes={288}
                quality={95}
            />
        </Box>
    )
}

function Ticker() {
    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text
                sx={{
                    fontSize: 2,
                    fontWeight: '500',
                    fontFamily: 'body',
                    letterSpacing: 1.1,
                    textTransform: 'uppercase',
                }}
            >
                4000/4000
            </Text>
            <Box p={2}>
                <Dot />
            </Box>
            <Text
                sx={{
                    fontSize: 2,
                    fontWeight: '500',
                    fontFamily: 'body',
                    letterSpacing: 1.1,
                    textTransform: 'uppercase',
                }}
            >
                collection sold out
            </Text>
            <Box p={2}>
                <Dot />
            </Box>
        </Flex>
    )
}

function Dot() {
    return (
        <Box
            sx={{
                width: '4px',
                height: '4px',
                borderRadius: '2px',
                bg: 'secondary',
            }}
        ></Box>
    )
}
