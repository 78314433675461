import { Box, Text, Grid, Flex, Button } from 'theme-ui'
import Image from 'next/image'

import Marquee from 'react-fast-marquee'

import RightArrow from '../../icons/RightArrow'

export default function Footer() {
    return (
        <Box>
            <Marquee gradient={false}>
                {[...Array(12)].map((el, i) => (
                    <CollectionDroppingSoon key={i} />
                ))}
            </Marquee>
            <Box
                sx={{
                    bg: '#C7B19C',
                    pt: 3,
                    pb: [0, 0, 8],
                    mt: 3,
                    position: 'relative',
                }}
            >
                <Text
                    as="h2"
                    sx={{
                        fontFamily: 'heading',
                        fontSize: [8, 8, '13vw'],
                        lineHeight: 1.1,
                        whiteSpace: ['auto', 'auto', 'nowrap'],
                        mt: 8,
                        textAlign: ['center', 'center', 'left'],
                        overflow: ['visible', 'visible', 'hidden'],
                    }}
                >
                    Join the{' '}
                    <Text as="span" sx={{ fontStyle: 'italic' }}>
                        community
                    </Text>
                    .
                </Text>
                <Flex
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 3,
                        pt: 2,
                    }}
                >
                    <Box sx={{ maxWidth: 1230, width: '100%' }}>
                        <Grid gap={5} columns={['1fr', '1fr', '580px auto']}>
                            <Box>
                                <Text
                                    as="p"
                                    sx={{
                                        fontSize: [3, 3, 4],
                                        textAlign: ['center', 'center', 'left'],
                                        fontWeight: 300,
                                        maxWidth: [
                                            'auto',
                                            'auto',
                                            '50vw',
                                            '45vw',
                                        ],
                                    }}
                                >
                                    Join our Discord channel or follow us on
                                    Twitter keep up to date with our latest work
                                    and announcements.
                                </Text>
                                <Flex
                                    sx={{
                                        mt: 6,
                                        flexDirection: [
                                            'column',
                                            'column',
                                            'row',
                                        ],
                                        justifyContent: [
                                            'center',
                                            'flex-start',
                                            'flex-start',
                                        ],
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        as="a"
                                        target="_blank"
                                        href="https://discord.gg/TYTxAE99HJ"
                                    >
                                        Join Discord
                                        <Box
                                            sx={{ display: 'inline-block' }}
                                            ml={3}
                                        >
                                            <RightArrow />
                                        </Box>
                                    </Button>

                                    <Button
                                        as="a"
                                        variant="secondary"
                                        ml={[0, 0, 2]}
                                        sx={{ mt: [2, 2, 0] }}
                                        href="https://twitter.com/tinyfacesnft"
                                        target="_blank"
                                    >
                                        Follow on Twitter
                                        <Box
                                            sx={{ display: 'inline-block' }}
                                            ml={3}
                                        >
                                            <RightArrow />
                                        </Box>
                                    </Button>
                                </Flex>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        maxWidth: '100%',
                                        justifyContent: 'center',
                                        position: [
                                            'static',
                                            'static',
                                            'absolute',
                                            'absolute',
                                            'absolute',
                                            'absolute',
                                        ],
                                        bottom: 0,
                                        right: [
                                            'auto',
                                            'auto',
                                            '0vw',
                                            '4vw',
                                            '8vw',
                                            'calc((100vw - 1230px) - 22vw)',
                                        ],
                                        zIndex: 1,
                                        overflow: 'hidden',
                                        mt: [0, 2, 0],
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: [
                                                250, 398, 398, 480, 520, 598,
                                            ],
                                            position: 'relative',
                                            height: [
                                                `calc(250px / (299 / 326))`,
                                                `calc(398px / (299 / 326))`,
                                                `calc(398px / (299 / 326))`,
                                                `calc(480px / (299 / 326))`,
                                                `calc(520px / (299 / 326))`,
                                                `calc(598px / (299 / 326))`,
                                            ],
                                        }}
                                    >
                                        <Image
                                            alt="Character with green cap and baseball jersey, buttoned up, digital rendering."
                                            sizes={598}
                                            fill
                                            quality={95}
                                            src="/pages/home/footer.png"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}

function CollectionDroppingSoon() {
    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text
                sx={{
                    fontSize: 2,
                    fontWeight: '500',
                    fontFamily: 'body',
                    letterSpacing: 1.1,
                    textTransform: 'uppercase',
                }}
            >
                4000/4000
            </Text>
            <Box p={2}>
                <Dot />
            </Box>
            <Text
                sx={{
                    fontSize: 2,
                    fontWeight: '500',
                    fontFamily: 'body',
                    letterSpacing: 1.1,
                    textTransform: 'uppercase',
                }}
            >
                collection sold out
            </Text>
            <Box p={2}>
                <Dot />
            </Box>
        </Flex>
    )
}

function Dot() {
    return (
        <Box
            sx={{
                width: '4px',
                height: '4px',
                borderRadius: '2px',
                bg: 'secondary',
            }}
        ></Box>
    )
}
