import Head from 'next/head'
import config from '../../data/config.json'

export default function Meta({ title, description, keywords, ogImage }) {
    return (
        <Head key={title}>
            {/* ⭐️ Base */}
            <title>{title || config.title}</title>
            <meta name="keywords" content={keywords || config.keywords}></meta>
            <meta
                name="description"
                content={description || config.description}
            ></meta>
            <meta charSet="utf-8" />
            <meta name="application-name" content="TinyFaces NFT" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />

            {/* 📘 OG */}
            <meta name="og:title" content={title || config.title}></meta>
            {ogImage && <meta property="og:image" content={ogImage} />}
            <meta
                name="og:description"
                content={description || config.description}
            ></meta>

            {/* 🐦 Twitter */}
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@TinyFacesNFT"></meta>
            <meta name="twitter:creator" content="@maximedegreve"></meta>
            <meta
                name="twitter:description"
                content={description || config.description}
            ></meta>

            <link
                rel="apple-touch-icon-precomposed"
                sizes="57x57"
                href="/meta/apple-touch-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="114x114"
                href="/meta/apple-touch-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="72x72"
                href="/meta/apple-touch-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="144x144"
                href="/meta/apple-touch-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="60x60"
                href="/meta/apple-touch-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="120x120"
                href="%PUBLIC_URL%/apple-touch-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="76x76"
                href="/meta/apple-touch-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon-precomposed"
                sizes="152x152"
                href="/meta/apple-touch-icon-152x152.png"
            />
            <link
                rel="icon"
                type="image/png"
                href="/meta/favicon-196x196.png"
                sizes="196x196"
            />
            <link
                rel="icon"
                type="image/png"
                href="/meta/favicon-96x96.png"
                sizes="96x96"
            />
            <link
                rel="icon"
                type="image/png"
                href="/meta/favicon-32x32.png"
                sizes="32x32"
            />
            <link
                rel="icon"
                type="image/png"
                href="/meta/favicon-16x16.png"
                sizes="16x16"
            />
            <link
                rel="icon"
                type="image/png"
                href="/meta/favicon-128.png"
                sizes="128x128"
            />
            <meta
                name="msapplication-TileImage"
                content="/meta/mstile-144x144.png"
            />
            <meta
                name="msapplication-square70x70logo"
                content="/meta/mstile-70x70.png"
            />
            <meta
                name="msapplication-square150x150logo"
                content="/meta/mstile-150x150.png"
            />
            <meta
                name="msapplication-wide310x150logo"
                content="/meta/mstile-310x150.png"
            />
            <meta
                name="msapplication-square310x310logo"
                content="/meta/mstile-310x310.png"
            />
            <link rel="icon" href="/meta/favicon.ico" />
        </Head>
    )
}
