import { Box } from 'theme-ui'
import Header from '../components/Header'
import Hero from '../components/pages/home/Hero'
import Info from '../components/pages/home/Info'
import Blocks from '../components/pages/home/Blocks'
import Carousel from '../components/pages/home/Carousel'
import Questions from '../components/pages/home/Questions'
import Footer from '../components/pages/home/Footer'
import Meta from '../components/Meta'

export default function Home() {
    return (
        <Box>
            <Meta />
            <Header />
            <Hero />
            <Info />
            <Blocks />
            <Carousel />
            <Questions />
            <Footer />
        </Box>
    )
}
