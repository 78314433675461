import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, Text, Flex, Grid, useThemeUI } from 'theme-ui'
import Image from 'next/image'

import LeftArrow from '../../icons/LeftArrow'
import RightArrow from '../../icons/RightArrow'

import 'swiper/css'
import 'swiper/css/pagination'

const images = [
    {
        id: 1,
        src: '/pages/home/characters-pick/character1.jpg',
        alt: 'character',
    },
    {
        id: 2,
        src: '/pages/home/characters-pick/character2.jpg',
        alt: 'character',
    },
    {
        id: 3,
        src: '/pages/home/characters-pick/character3.jpg',
        alt: 'character',
    },
    {
        id: 4,
        src: '/pages/home/characters-pick/character4.jpg',
        alt: 'character',
    },
    {
        id: 5,
        src: '/pages/home/characters-pick/character5.jpg',
        alt: 'character',
    },
    {
        id: 6,
        src: '/pages/home/characters-pick/character6.jpg',
        alt: 'character',
    },
]

const WIDTH_CARD = ['30vw', '30vw', '30vw', '500px']
const ASPECT_RATIO_IMAGE = '25 / 32'
const MAX_WIDTH = 1230

export default function Carousel() {
    const swiperRef = useRef(null)
    const context = useThemeUI()
    const paddingLeft = `(100vw - ${MAX_WIDTH}px) / 2`
    const paddingLeftSmall = `${context.theme.space[5]}px`

    return (
        <Box
            sx={{
                bg: 'backgroundDark',
                pt: [7, 7, 8],
                pb: [8, 8, 9],
                overflow: 'hidden',
            }}
        >
            <Flex
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: [5, 5, 8],
                }}
            >
                <Flex
                    sx={{
                        maxWidth: 1230,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: [5, 5, 0],
                    }}
                >
                    <Text
                        as="h2"
                        sx={{
                            fontSize: [6, 6, 8],
                            fontFamily: 'heading',
                            display: 'block',
                            pr: 2,
                            color: 'textInversed',
                            lineHeight: 1.0,
                        }}
                    >
                        Find your favourite character
                    </Text>

                    <Grid gap={3} columns={2} sx={{ flexShrink: 0 }}>
                        <NavButton
                            onClick={() => swiperRef.current.swiper.slidePrev()}
                            direction="left"
                        />
                        <NavButton
                            onClick={() => swiperRef.current.swiper.slideNext()}
                            direction="right"
                        />
                    </Grid>
                </Flex>
            </Flex>
            <Box
                sx={{
                    '.swiper-slide': {
                        width: WIDTH_CARD,
                        display: 'flex',
                        height: [
                            `calc(${WIDTH_CARD[0]} / (${ASPECT_RATIO_IMAGE}))`,
                            `calc(${WIDTH_CARD[1]} / (${ASPECT_RATIO_IMAGE}))`,
                            `calc(${WIDTH_CARD[2]} / (${ASPECT_RATIO_IMAGE}))`,
                            `calc(${WIDTH_CARD[3]} / (${ASPECT_RATIO_IMAGE}))`,
                        ],
                    },
                    '.swiper-slide:first-of-type': {
                        justifyContent: 'flex-end',
                        width: [
                            `calc(${WIDTH_CARD[0]} + ${paddingLeftSmall})`,
                            `calc(${WIDTH_CARD[1]} + ${paddingLeftSmall})`,
                            `max(${WIDTH_CARD[2]},calc(${WIDTH_CARD[2]} + ${paddingLeft}))`,
                            `calc(${WIDTH_CARD[3]} + ${paddingLeft})`,
                        ],
                    },
                    '.swiper-slide:last-of-type': {
                        width: [
                            `calc(${WIDTH_CARD[0]} + ${paddingLeftSmall})`,
                            `calc(${WIDTH_CARD[1]} + ${paddingLeftSmall})`,
                            `max(${WIDTH_CARD[2]},calc(${WIDTH_CARD[2]} + ${paddingLeft}))`,
                            `calc(${WIDTH_CARD[3]} + ${paddingLeft})`,
                        ],
                    },
                }}
            >
                <Swiper slidesPerView="auto" spaceBetween={30} ref={swiperRef}>
                    {images.map((image) => (
                        <SwiperSlide key={image.id}>
                            <Character src={image.src} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Box>
    )
}

function NavButton({ direction, onClick }) {
    return (
        <Box
            as="button"
            sx={{
                color: 'textInversed',
                width: 44,
                cursor: 'pointer',
                bg: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 44,
                borderRadius: 22,
                borderColor: 'textInversed',
                borderStyle: 'solid',
                borderWidth: 1,
            }}
            aria-label={
                direction === 'left'
                    ? 'Navigate left in the carousel'
                    : 'Navigate right in the carousel'
            }
            onClick={onClick}
        >
            {direction === 'left' ? <LeftArrow /> : <RightArrow />}
        </Box>
    )
}
function Character({ src }) {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                    position: 'relative',
                    width: WIDTH_CARD,
                }}
            >
                <Image
                    alt="TinyFaces NFT example artwork"
                    sizes={WIDTH_CARD}
                    fill
                    src={src}
                    quality={95}
                />
            </Box>
        </>
    )
}
