import { Box, Text, Grid, Flex } from 'theme-ui'
import Image from 'next/image'

export default function Blocks() {
    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                pt: [4, 4, 6],
                pb: [7, 7, 9],
                px: [3, 3, 6],
            }}
        >
            <Box sx={{ maxWidth: 1230, width: '100%' }}>
                <Grid
                    columns={['1fr', '1fr', '1fr 1fr']}
                    sx={{ maxWidth: '100%' }}
                >
                    <Box
                        sx={{ bg: '#A0B1CD', width: '100%', borderRadius: 10 }}
                    >
                        <Box sx={{ padding: [4, 4, 7] }}>
                            <Text
                                sx={{
                                    fontSize: [7, 7, 9],
                                    display: 'block',
                                    fontFamily: 'heading',
                                    lineHeight: 1,
                                }}
                            >
                                ✺
                            </Text>
                            <Text
                                as="h2"
                                sx={{
                                    fontSize: [6, 6, 8],
                                    lineHeight: 1.3,
                                    fontFamily: 'heading',
                                    display: 'block',
                                }}
                            >
                                4000{' '}
                                <Text as="span" sx={{ fontStyle: 'italic' }}>
                                    unique
                                </Text>{' '}
                                TinyFaces
                            </Text>
                            <Text
                                as="p"
                                sx={{ fontSize: [2, 2, 3], fontWeight: 300 }}
                            >
                                Each character is entirely unique and is
                                generated by combining attributes such as colour
                                palettes, skin tones, facial traits, outfits and
                                accessories. With nearly endless combinations,
                                all characters are guaranteed to be one of a
                                kind.
                            </Text>
                        </Box>

                        <video
                            width="100%"
                            height="auto"
                            loop
                            muted
                            autoPlay
                            playsInline
                        >
                            <source
                                src="https://storage.googleapis.com/tinyfaces-nft-web/hats.mov"
                                type="video/quicktime"
                            />
                            <source
                                src="https://storage.googleapis.com/tinyfaces-nft-web/hats.webm"
                                type="video/webm"
                            />
                        </video>
                    </Box>
                    <Flex
                        sx={{
                            bg: '#E5CCE0',
                            width: '100%',
                            borderRadius: 10,
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                padding: [4, 4, 7],
                                flex: 1,
                            }}
                        >
                            <Text
                                sx={{
                                    fontSize: [7, 7, 9],
                                    display: 'block',
                                    fontFamily: 'heading',
                                    lineHeight: 1,
                                }}
                            >
                                ✺
                            </Text>
                            <Text
                                sx={{
                                    fontSize: [6, 6, 8],
                                    lineHeight: 1.3,
                                    fontFamily: 'heading',
                                    display: 'block',
                                }}
                                as="h2"
                            >
                                Secure the most{' '}
                                <Text as="span" sx={{ fontStyle: 'italic' }}>
                                    rare
                                </Text>{' '}
                            </Text>
                            <Text
                                as="p"
                                sx={{ fontSize: [2, 2, 3], fontWeight: 300 }}
                            >
                                Even though no two Tinyfaces will ever be the
                                same, some variations are rarer than others.
                                Watch out for special accessories and raw
                                materials - the rarer the attributes, the rarer
                                your character will be.
                            </Text>
                        </Box>
                        <Flex
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: [4, 4, 0],
                                position: 'relative',
                            }}
                        >
                            <Image
                                alt="Black steel character wearing a bucket hat and round glasses, digital rendering."
                                width={363}
                                height={385}
                                src="/pages/home/rare.png"
                            />
                        </Flex>
                    </Flex>
                </Grid>
                <Grid
                    columns={['1fr', '1fr', '1fr', '52fr 48fr']}
                    gap={[0, 0, 2]}
                    sx={{
                        maxWidth: '100%',
                        bg: '#8D9876',
                        mt: 3,
                        width: '100%',
                        borderRadius: 10,
                    }}
                >
                    <Flex
                        sx={{
                            py: [4, 4, 8, 8],
                            pl: [4, 4, 8, 8],
                            pr: [5, 6, 6, 0],
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            sx={{
                                fontSize: [7, 7, 9],
                                display: 'block',
                                fontFamily: 'heading',
                                lineHeight: 1,
                                color: 'textInversedSoft',
                            }}
                        >
                            ✺
                        </Text>
                        <Text
                            as="h2"
                            sx={{
                                fontSize: [6, 6, 9],
                                lineHeight: [1.3, 1.3, 1],
                                fontFamily: 'heading',
                                display: 'block',
                                color: 'textInversedSoft',
                                mt: [0, 0, 3],
                            }}
                        >
                            Our environmental impact{' '}
                            <Text as="span" sx={{ fontStyle: 'italic' }}>
                                matters
                            </Text>{' '}
                            to us.
                        </Text>
                        <Text
                            as="p"
                            sx={{
                                fontSize: [2, 2, 3],
                                fontWeight: 300,
                                maxWidth: 486,
                                mt: [0, 0, 3],
                            }}
                        >
                            In order to enable our community to spend as little
                            as possible in gas fees when minting, we opted for a
                            contract made by Azuki called ERC721A, which allows
                            minting multiple NFTs for essentially the same cost
                            as minting a single one.
                        </Text>
                    </Flex>
                    <Box
                        sx={{
                            display: ['none', 'none', 'none', 'flex', 'flex'],
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                overflow: 'hidden',
                                position: 'relative',
                                width: [631, 631, '40vw', '44vw', 540],
                                height: [
                                    'calc(200px / (631/600))',
                                    'calc(40vw / (631/600))',
                                    'calc(40vw / (631/600))',
                                    'calc(44vw / (631/600))',
                                    'calc(540px / (631/600))',
                                ],
                            }}
                        >
                            <Image
                                alt="Character with beige hat, green band, round glasses, and white facemask; digital illustration."
                                sizes={631}
                                fill
                                quality={95}
                                src="/pages/home/facemask.png"
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: ['flex', 'flex', 'flex', 'none'],
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                width: '60vw',
                                height: 'calc(60vw / (631/600))',
                            }}
                        >
                            <Image
                                alt="Character with beige hat, green band, round glasses, and white facemask; digital illustration."
                                src="/pages/home/facemask.png"
                                fill
                                sizes={631}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </Flex>
    )
}
